/**
 * Data provider for DEX cart.
 */
import { createCart, loadCartData, addItemToCart, removeItemFromCart, applyPromotionCodeToCart, removePromotionCodeFromCart, getProduct, updateUserCountry, convertCart, getUserCountry, isCartPaidFor } from './cartUtils';
import logger from './logger';
import { checkBool } from '../../universal-components/src/utils';
import { DEV_ENV, PROD_ENV, UAT_ENV } from './constants/environments';
import { subscribe, events, emit } from './eventManager';
import { COOKIE_DEFAULT_PATH, COOKIE_SAMESITE_STRICT, deleteCookie, getCookieDomain, getCookieValue, setCookie } from './utils/cookies';
import { DEX_CHECKOUT_SERVICE_URL, WEBKEY_UPDATE_PROFILE_URL, WEBKEY_UPDATE_PROFILE_URL_DEV, getDEXServicesAPIURL } from './constants/api_urls';
/**
 * Name of the storage key for storing the cart ID. Cart ID is currently stored in cookies, so it represents the cookie name.
 */
const CART_STORAGE_KEY_SUFFIX = 'cartId';
/**
 * Message returned from DEX in the case where an assigned cart is being loaded without the correct email address.
 */
const USER_CART_MISTMATCH_ERR = 'user cart mismatch';
/**
 * Log message displayed if no cart ID can be found in cookies.
 */
const NO_CART_ID_ERROR = 'No cartId available, cart not initialized';
/**
 * Represents how DEX describe an annual pricing subscription term. This should ONLY change in coordination with DEX.
 */
export const ANNUAL_SUBSCRIPTION_TERM = 'Yearly';
/**
 * Represents how DEX describe an annual pricing display term. This should ONLY change in coordination with DEX.
 */
export const ANNUAL_SUBSCRIPTION_DISPLAY_TERM = 'Annual Subscription';
/**
 * Represents how DEX describe a monthly pricing subscription term. This should ONLY change in coordination with DEX.
 */
export const MONTHLY_SUBSCRIPTION_TERM = 'Monthly';
/**
 * Represents how DEX describe an monthly pricing display term. This should ONLY change in coordination with DEX.
 */
export const MONTHLY_SUBSCRIPTION_DISPLAY_TERM = 'Monthly Subscription';
/**
 * Represents how DEX describe an 3 year pricing display term. This should ONLY change in coordination with DEX.
 */
export const THREE_YEAR_SUBSCRIPTION_DISPLAY_TERM = '3 Year Subscription';
/**
 * Represents how DEX describe an 3 months pricing display term. This should ONLY change in coordination with DEX.
 */
export const THREE_MOTHS_TRIAL_DISPLAY_TERM = '3 Month Trial';
/**
 * Represents which subscription terms we support
 */
const DEFAULT_SUBSCRIPTION_TERMS_SUPPORTED = [MONTHLY_SUBSCRIPTION_TERM.toLowerCase(), ANNUAL_SUBSCRIPTION_TERM.toLowerCase()];
/**
 * Represents which subscription terms we support
 */
const DEFAULT_DISPLAY_TERMS_SUPPORTED = [MONTHLY_SUBSCRIPTION_DISPLAY_TERM.toLowerCase(), ANNUAL_SUBSCRIPTION_DISPLAY_TERM.toLowerCase(), THREE_YEAR_SUBSCRIPTION_DISPLAY_TERM.toLowerCase()];
// Query Parameters which control some views
const FULL_CART_QUERY_PARAM = 'fullCart';
const CART_COOKIE_MAX_AGE = 2592000; // 30 days
/**
 * Message returned from DEX in the case cart id is not valid.
 */
const CART_NOT_FOUND = 'cart not found';
/**
 * Error code for duplicate subscription
 */
const SAME_PRODUCT_DIFFERENT_TERM = 'SAME_PRODUCT_DIFFERENT_TERM';
/**
 * Shopping cart environment which is used to inform the cart utility which API environment should be used to pull data
 * from the Digital Exchange.
 */
let cartEnv = PROD_ENV;
/**
 * The value which determines if the cart's onsite checkout is enabled. Otherwise we will pass the user to the old DEX checkout flow
 */
let checkoutEnabled = false;
/**
 * Represents the shopping cart cookie domain. Cookies are used to transfer carts across sites using top-level domain
 * cookies. Note that the cookies are also set per environment via the domain on the cookie.
 */
let cartCookieDomain;
/**
 * Checkout service API URL (potentially updated in the chat initialization).
 */
let dexCheckoutServiceURL = DEX_CHECKOUT_SERVICE_URL;
/**
 * Webkey update profile URL presented to users if they have an unsupported webkey country (potentially
 * updated in the live chat initialization).
 */
let webkeyUpdateProfileURL = WEBKEY_UPDATE_PROFILE_URL;
/**
 * Represents the potential errors emitted by the cart module. The cart module emits events with two properties:
 *
 *  (1) the shopping cart data (potentially null in the case there is an issue fetching data).
 *  (2) error retrieving shopping cart data.
 *
 */
const CART_EVENT_ERRORS = {
    CART_ERROR_INITIALIZING_CART: 'initializingCart',
    CART_ERROR_FETCHING_CART_DATA: 'fetchingCartData',
    CART_CLEARED_AFTER_CHECKOUT: 'cartClearedAfterCheckout'
};
/**
 * Represents the potential eCommerce events emitted by the cart module. The cart module emits events during eCommerce interactions
 * so that independent components can consume them (product fetched, etc).
 *
 */
const ECOMMERCE_EVENTS = {
    ECOMMERCE_PRODUCT_FETCHED: 'productFetched'
};
/**
 * Initializes cart.  This will utilize the countrycode passed in the countryObject
 * to initialize a DEX cart (if a cart has not already been initialized and a cookie
 * stored for the cartId)
 *
 * @param {CartConfiguration} config the countryObject, optional env to used for API calls, and the checkout enabled status
 */
export async function initializeCart(config = {}) {
    const { env = PROD_ENV, checkout } = config;
    cartEnv = env;
    if (checkout) {
        checkoutEnabled = checkBool(checkout.enabled, false);
    }
    // Get the domain to use for any cookies utilized for the cart
    cartCookieDomain = getCookieDomain([DEV_ENV, UAT_ENV].includes(cartEnv));
    // Set the Checkout Service URL according to the configured environment
    dexCheckoutServiceURL = `${getDEXServicesAPIURL(cartEnv)}/checkout`;
    // Set the webkeyUpdateProfileURL based on environment
    if (cartEnv === DEV_ENV) {
        webkeyUpdateProfileURL = WEBKEY_UPDATE_PROFILE_URL_DEV;
    }
    else if (cartEnv === UAT_ENV) {
        // We want to mirror the production environment with these values
        webkeyUpdateProfileURL = WEBKEY_UPDATE_PROFILE_URL;
    }
    else {
        // Default to production
        webkeyUpdateProfileURL = WEBKEY_UPDATE_PROFILE_URL;
    }
    // Subscribe to country changes (cart is tied to the country so a switch requires updates)
    window.disw.country.subscribeToCountry(_onCountryChanged);
    // Register the analytics modules.
    window.disw.cartAnalytics.registerAnalytics();
    // Note: the country is set no matter what so let the subscription do the work
}
/**
 * Creates/Updates a cookie to store the cartId
 */
const _setCartCookie = (key, value) => {
    setCookie(key, value, {
        maxAge: CART_COOKIE_MAX_AGE,
        domain: cartCookieDomain,
        path: COOKIE_DEFAULT_PATH,
        // Only sends the cookie with requests from the cookie's origin site. Server doesn't use this cookie so don't send any more than necessary
        sameSite: COOKIE_SAMESITE_STRICT
    });
};
/**
 * Get the cartId from the cookie
 *
 * @param {string} key
 * @returns {string|undefined} Cookie value or undefined if it doesn't exist
 */
const _getCartCookieValue = (key) => {
    return getCookieValue(key);
};
/**
 *
 */
const _deleteCartCookie = (key) => {
    deleteCookie(key, { domain: cartCookieDomain, path: COOKIE_DEFAULT_PATH });
};
/**
 * Validate that the cart is assigned to the correct user. If we receive an error from DEX indicating that a cart is assigned
 * to the wrong user, we should create a new cart overriding the current cart.
 *
 * @param {*} error returned from the DEX API for loadCart API calls.
 */
const _validateCartAssignment = async (error) => {
    try {
        // if we have a cart mismatch when getting the cart, we need to make a new cart and overwrite the old one
        if (typeof error === 'string' && JSON.parse(error).message === USER_CART_MISTMATCH_ERR) {
            // since this is not technically an error, we send out a warning describing that a mismatch occured likely because a user logged out
            logger.warn('Cart Mismatch, the current cart ID does not match to current account information (likely due to a logout). Dumping old cart and creating a new one.');
            _createCart(window.disw.country.getCountry());
        }
    }
    catch (error) {
        logger.error(error);
    }
};
/**
 * Utility method for initializing a DEX cart. Note: Exported for unit test purposes
 *
 * @param {Country} countryObject
 *
 * @deprecated since 12/20/22.
 *
 */
export const _loadOrCreateCart = async (countryObject) => {
    const cartId = await _loadCart(countryObject);
    return cartId ? cartId : _createCart(countryObject);
};
/**
 * Utility method to load cart data.  Note: Exported for unit test purposes
 *
 * @param {Country} countryObject
 */
export const _loadCart = async (countryObject) => {
    const storageItemKey = `${countryObject.countrycode}_${CART_STORAGE_KEY_SUFFIX}`;
    const cartId = _getCartCookieValue(storageItemKey);
    if (cartId) {
        try {
            // Get the cart items
            const cartData = await loadCartData(cartEnv, cartId);
            // If we're opening the full cart or refresh the full cart browser, track the viewed event.
            const viewFullCartPage = new URLSearchParams(window.location.search).has(FULL_CART_QUERY_PARAM);
            if (viewFullCartPage) {
                window.disw.cartAnalytics.cartViewed(cartData);
            }
            if (cartData) {
                // If tax has been applied to the shopping cart already, create them a new cart. It means the customer
                // reached the order review page, at which point they cannot make further modifications to their cart.
                if (isCartPaidFor(cartData)) {
                    return _createCart(countryObject);
                }
                // Is anybody out there?
                emit(events.CART, cartData, false);
            }
        }
        catch (error) {
            logger.error(`Error loading cart data: ${error}`);
            // Remove invalid cart id from cookie
            removeInvalidCartId(storageItemKey, error);
            emit(events.CART, null, CART_EVENT_ERRORS.CART_ERROR_FETCHING_CART_DATA);
            // check if the cart is assigned to the wrong user, if so, create a new one.
            _validateCartAssignment(error);
        }
        // No need to create a new cart, so we're done here
        return cartId;
    }
};
/**
 * Function to remove invalid cart ID from storage
 * DEX API call returns a "cart not found" message for invalid cart Ids.
 *
 * @param {string} storageItemKey cookie name
 * @param {string} error Message returns from DEX api call
 * @returns
 */
function removeInvalidCartId(storageItemKey, error) {
    try {
        // Parse the error message
        const errorMessage = typeof error === 'string' ? error : error.message;
        const errorObject = typeof errorMessage === 'string' ? JSON.parse(errorMessage) : null;
        // Check if the error message indicates a cart not found error
        if (errorObject && errorObject.message === CART_NOT_FOUND) {
            // Delete the cart cookie
            _deleteCartCookie(storageItemKey);
        }
    }
    catch (jsonError) {
        // Handle errors that occur during JSON parsing
        console.error('Error parsing JSON:', jsonError);
    }
}
/**
 * Utility method to create a single DEX cart. Note the function will not try to load an existing cart,
 * and instead will create a new cart and overwrite one if it exists.
 *
 * @param {*} countryObject
 * @returns
 */
const _createCart = async (countryObject) => {
    const storageItemKey = `${countryObject.countrycode}_${CART_STORAGE_KEY_SUFFIX}`;
    // No current cart, create a new one
    try {
        const cartData = await createCart(cartEnv, countryObject.countrycode);
        if (cartData) {
            // Set a property to indicate it's a new cart
            cartData.newCart = true;
            // Store the cartId for later use
            _setCartCookie(storageItemKey, cartData.id);
            // Is anybody listening?
            emit(events.CART, cartData, false);
            return cartData.id;
        }
    }
    catch (error) {
        logger.error(error);
        emit(events.CART, null, CART_EVENT_ERRORS.CART_ERROR_INITIALIZING_CART);
    }
};
/**
 * Get the cartId from cookie
 */
const _getCartId = () => {
    const storageItemKey = `${window.disw.country.getCountry().countrycode}_${CART_STORAGE_KEY_SUFFIX}`;
    return _getCartCookieValue(storageItemKey);
};
/**
 * Catch when a country has changed, attempt to switch to a cart for the new countrycode.  If that doesn't exist
 * create a new cart for that countrycode
 *
 * @param {Country} countryObject containing countryname and countrycode
 */
const _onCountryChanged = async (countryObject, closeSelected) => {
    // If the country modal is closed, and no country is selected - don't load/create cart.
    if (closeSelected) {
        return;
    }
    // We need to load the cart for the new country code but no need to create a new one if there is no cart
    const cartId = await _loadCart(countryObject);
    // If we can't find a cart ID for the current country, emit an initialization error so we can mimick an empty cart.
    if (!cartId) {
        emit(events.CART, null, CART_EVENT_ERRORS.CART_ERROR_INITIALIZING_CART);
    }
};
/**
 * Function to subscribe to any changes in the cart.
 *
 * @param {CallbackFunction} callback
 * @return {function} function used to unsubscribe from the cart events
 */
function subscribeToCart(callback) {
    return subscribe(events.CART, callback);
}
/**
 * Function to subscribe to any eCommerce events which are emitted (product fetches, etc).
 *
 * @param {CallbackFunction} callback executed when the event is emitted.
 *
 */
function subscribeToECommerce(callback) {
    return subscribe(events.ECOMMERCE, callback);
}
/**
 * Get the cart data from DEX middleware.  Id of the cart would have been stored in
 * a cookie. If cart has not been initialized, an error is thrown.
 *
 * @returns {Object} Object of data from DEX for the stored cartId
 */
async function getCart() {
    const cartId = _getCartId();
    if (!cartId) {
        logger.warn(NO_CART_ID_ERROR);
        throw NO_CART_ID_ERROR;
    }
    try {
        const cartData = await loadCartData(cartEnv, cartId);
        // If the cart is paid for already, create them a new cart. It means the customer
        // reached the order review page, at which point they cannot make further modifications to their cart.
        if (isCartPaidFor(cartData)) {
            const countryObject = window.disw.country.getCountry();
            return _createCart(countryObject);
        }
        emit(events.CART, cartData, false);
        return cartData;
    }
    catch (error) {
        logger.error(`Error loading cart data: ${error}`);
        emit(events.CART, null, CART_EVENT_ERRORS.CART_ERROR_FETCHING_CART_DATA);
        // check if the cart is assigned to the wrong user, if so, create a new one.
        _validateCartAssignment(error);
        // Throw the error so it can be caught
        throw error;
    }
}
/**
 * Adds an item to the DEX cart.  Requires the specific product pricing id from the pricing options
 * as well the quantity desired.
 *
 * @param {string} productPricingId the pricingId for the item to add to the cart.
 * @param {number} quantity the number of the given productPricingId to add.
 * @param {string} context a string identifying the consumer so we can handle errors in different calling contexts.
 * @param {Object} cartItem item object for the current product. This is either the universal components {@link ICartItem} or a {@link SharedLibraryProduct} object.
 * @param {string} promotionCode a string identifying the consumer so we can handle errors in different calling contexts
 * @param {string} itemIndex index of item in cart, null of not sourced from cart
 *
 * @returns {Promise} Promise object representing the cart data as returned from the DEX middleware
 */
async function addOrSubtractFromCart(productPricingId, quantity, cartItem, context, promotionCode, itemIndex) {
    let cartId = _getCartId();
    // If no error context is passed, use the cart item id unless the product is being added by the cartButton.
    if (!context) {
        context = typeof cartItem.id === 'undefined' ? 'cartButton' : cartItem.id;
    }
    // If we cannot find the cart id try to make a new cart
    if (!cartId) {
        // retry cart init
        const countryObject = window.disw.country.getCountry();
        // load the cart and if there is no cart id currently than create a new cart
        cartId = await _createCart(countryObject);
        // if it fails twice then return full error
        if (!cartId) {
            logger.error(NO_CART_ID_ERROR);
            // return error so we can display to user
            emit(events.CART, null, context);
            throw new Error('Cart cannot be initialized.');
        }
    }
    // Determine the product's SKU depending on whether the cartItem is a ICartItem or a SharedLibraryProduct
    const sku = 'sku' in cartItem ? cartItem.sku : cartItem.product.productSku;
    try {
        // If the quantity is negative, we track removing the product so the tracking data is still available in the cart.
        if (quantity < 0) {
            window.disw.cartAnalytics.productDecreased(productPricingId, promotionCode, itemIndex, quantity);
        }
        // Add (or remove) the item to/from the shopping cart.
        const cartData = await addItemToCart({
            env: cartEnv,
            cartId,
            items: [
                {
                    pricingId: productPricingId,
                    sku,
                    quantity
                }
            ]
        });
        //If there is a duplicate subscription error
        if ((cartData === null || cartData === void 0 ? void 0 : cartData.errorCode) === SAME_PRODUCT_DIFFERENT_TERM) {
            logger.error('Fatal DEX error has occurred, Same product different term error.');
            return cartData === null || cartData === void 0 ? void 0 : cartData.errorCode;
        }
        // The shopping cart was updated, so register the update so that the product added call is using latest data.
        window.disw.cartAnalytics.registerCartUpdate(cartData);
        // If the quantity is > 0, we track adding the product after it has been added to ensure data availability at the time of the track event.
        if (quantity > 0) {
            window.disw.cartAnalytics.productAdded(productPricingId, promotionCode, itemIndex, quantity);
        }
        if (cartData) {
            emit(events.CART, cartData, false);
        }
        else {
            logger.error('Fatal DEX error has occurred, DEX has returned empty cart data with response code 2xx.');
            throw Error('Fatal DEX error has occurred');
        }
        return cartData;
    }
    catch (error) {
        logger.error(`Error adding item to cart ${error} with error context '${context}'`);
        emit(events.CART, null, context);
        throw error;
    }
}
/**
 * Adds one or multiple items to the DEX cart. Requires detailed parameters for each item, such as
 * product pricing ID, quantity, and optional promotion codes. This function handles either a
 * single item or an array of items to be added.
 *
 * @param {AddToCartParameter | AddToCartParameter[]} parameter - An object or an array of objects
 * representing the parameters needed to add items to the cart. Each parameter object includes:
 * - `productPricingId` {string}: the pricingId for the item to add to the cart.
 * - `quantity` {number}: the number of the given productPricingId to add.
 * - `cartItem` {ICartItem | SharedLibraryProduct}: item object for the current product. This is
 *   either the universal components {@link ICartItem} or a {@link SharedLibraryProduct} object.
 * - `context` {string | undefined}: a string identifying the consumer so we can handle errors in different calling contexts.
 * - `promotionCode` {string}: An optional string representing a promotion code associated with
 *   the item, used for special offers or discounts.
 * - `itemIndex` {string}: index of item in cart, null of not sourced from cart
 *
 * @returns {Promise} Promise object representing the cart data as returned from the DEX middleware
 */
async function addOrSubtractProductsFromCart(parameter) {
    const hasMultipleProducts = Array.isArray(parameter);
    const parametersList = hasMultipleProducts ? parameter : [parameter];
    const quantity = parametersList[0].quantity;
    const promotionCode = parametersList[0].promotionCode;
    const itemIndex = parametersList[0].itemIndex;
    let context = parametersList[0].context;
    let cartId = _getCartId();
    // If no error context is passed, use the cart item id unless the product is being added by the cartButton.
    if (!context) {
        context = typeof parametersList[0].cartItem.id === 'undefined' ? 'cartButton' : parametersList[0].cartItem.id;
    }
    // If we cannot find the cart id try to make a new cart
    if (!cartId) {
        // retry cart init
        const countryObject = window.disw.country.getCountry();
        // load the cart and if there is no cart id currently than create a new cart
        cartId = await _createCart(countryObject);
        // if it fails twice then return full error
        if (!cartId) {
            logger.error(NO_CART_ID_ERROR);
            // return error so we can display to user
            emit(events.CART, null, context);
            throw new Error('Cart cannot be initialized.');
        }
    }
    const items = parametersList.map(({ productPricingId, cartItem, quantity }) => ({
        pricingId: productPricingId,
        sku: 'sku' in cartItem ? cartItem.sku : cartItem.product.productSku,
        quantity
    }));
    try {
        // If the quantity is negative, we track removing the product so the tracking data is still available in the cart.
        if (quantity < 0) {
            parametersList.forEach(({ productPricingId }) => {
                window.disw.cartAnalytics.productDecreased(productPricingId, promotionCode, itemIndex, quantity);
            });
        }
        // Add (or remove) the item to/from the shopping cart.
        const cartData = await addItemToCart({
            env: cartEnv,
            cartId,
            items
        });
        //If there is a duplicate subscription error
        if ((cartData === null || cartData === void 0 ? void 0 : cartData.errorCode) === SAME_PRODUCT_DIFFERENT_TERM) {
            logger.error('Fatal DEX error has occurred, Same product different term error.');
            return cartData === null || cartData === void 0 ? void 0 : cartData.errorCode;
        }
        // The shopping cart was updated, so register the update so that the product added call is using latest data.
        window.disw.cartAnalytics.registerCartUpdate(cartData);
        // If the quantity is > 0, we track adding the product after it has been added to ensure data availability at the time of the track event.
        if (quantity > 0) {
            parametersList.forEach(({ productPricingId }) => {
                window.disw.cartAnalytics.productAdded(productPricingId, promotionCode, itemIndex, quantity);
            });
        }
        if (cartData) {
            emit(events.CART, cartData, false);
        }
        else {
            logger.error('Fatal DEX error has occurred, DEX has returned empty cart data with response code 2xx.');
            throw Error('Fatal DEX error has occurred');
        }
        return cartData;
    }
    catch (error) {
        logger.error(`Error adding item to cart ${error} with error context '${context}'`);
        emit(events.CART, null, context);
        throw error;
    }
}
function subscribeToMiniCart(callback) {
    return subscribe(events.MINI_CART, callback);
}
function toggleMiniCart(state) {
    emit(events.MINI_CART, state);
}
function subscribeToFullCart(callback) {
    return subscribe(events.FULL_CART, callback);
}
/**
 * Changes fullcart open state, and passes params to analyics if cart is being opened
 *
 * @param {boolean} state if the cart should be open or not
 *
 */
async function toggleFullCart(state) {
    // If we're opening the full cart, track the viewed event.
    state && window.disw.cartAnalytics.cartViewed();
    emit(events.FULL_CART, state);
}
/**
 * Removes one or multiple products from the shopping cart.
 *
 * This function handles the removal of products from the cart, updates the cart data,
 * and emits events to notify listeners about the changes. It can process either a single
 * product removal or multiple product removals at once.
 *
 * @param {RemoveProductParameter | RemoveProductParameter[]} parameter - An object or array of objects containing information about the product(s) to be removed.
 * @param {string} parameter.productPricingId - The pricing ID of the product to be removed.
 * @param {ICartItem} parameter.removedProduct - The cart item object representing the product to be removed.
 * @param {string | null} parameter.promotionCode - The promotion code associated with the product, if any.
 * @param {string | null} parameter.itemIndex - The index of the item in the cart, if applicable.
 *
 * @returns {Promise<CartData | undefined>} A promise that resolves to the updated cart data after removal,
 *                                          or undefined if the operation fails.
 *
 * @throws {Error} Throws an error if the cart ID is not found or if there's an error during the removal process.
 */
async function removeProductFromCart(parameter) {
    const hasMultipleProducts = Array.isArray(parameter);
    const parametersList = hasMultipleProducts ? parameter : [parameter];
    const cartId = _getCartId();
    if (!cartId) {
        logger.warn(NO_CART_ID_ERROR);
        throw new Error(NO_CART_ID_ERROR);
    }
    try {
        // Track the removal of the all cart item (not to be confused with the decrease operation). This represents moving the item with all licenses.
        parametersList.forEach(({ productPricingId, promotionCode, itemIndex, removedProduct }) => {
            window.disw.cartAnalytics.productRemoved(productPricingId, promotionCode, itemIndex, removedProduct.quantity);
        });
        const cartItemsIds = parametersList.map(({ removedProduct }) => removedProduct.id);
        // Remove all items from the shopping cart and capture the updated shopping cart data.
        const cartData = await removeItemFromCart(cartEnv, cartId, cartItemsIds);
        // Let anyone else listening that the cart contents have changed
        if (cartData) {
            emit(events.CART, cartData, false);
            return cartData;
        }
        return cartData;
    }
    catch (error) {
        logger.error(error);
        // Throw the error so it can be caught
        throw error;
    }
}
/**
 * Removes an item from the DEX cart.
 *
 * @param {string} cartItemId the id for the cart item to be removed
 *
 * @returns {Promise} Promise object representing the cart data as returned from the DEX middleware
 */
async function removeFromCart(cartItemId, removedProduct, promotionCode, itemIndex) {
    const cartId = _getCartId();
    if (!cartId) {
        logger.warn(NO_CART_ID_ERROR);
        throw NO_CART_ID_ERROR;
    }
    try {
        // Track the removal of the cart item (not to be confused with the decrease operation). This represents moving the item with all licenses.
        window.disw.cartAnalytics.productRemoved(cartItemId, promotionCode, itemIndex, removedProduct.quantity);
        // Remove the item from the shopping cart and capture the updated shopping cart data.
        const cartData = await removeItemFromCart(cartEnv, cartId, [cartItemId]);
        // Let anyone else listening that the cart contents have changed
        if (cartData) {
            emit(events.CART, cartData, false);
        }
        return cartData;
    }
    catch (error) {
        logger.error(error);
        // Throw the error so it can be caught
        throw error;
    }
}
/**
 * Applies a promotional code to the DEX cart.
 *
 * @param {string} promotionCode the promotion code to apply to the cart
 *
 * @returns {Promise} Promise object representing the cart data as returned from the DEX middleware or undefined in the
 *  case of an fetch error
 */
async function applyPromotion(promotionCode) {
    const cartId = _getCartId();
    if (!cartId) {
        logger.warn(NO_CART_ID_ERROR);
        throw NO_CART_ID_ERROR;
    }
    //When the discountCode is entered, the code entered and the cartid is sent to the cartanalytics to keep track of the entered discount code
    window.disw.cartAnalytics.couponEntered(promotionCode);
    try {
        const cartData = await applyPromotionCodeToCart(cartEnv, cartId, promotionCode);
        // If message exists, the promotion code was not valid
        const promotionCodeApplied = cartData.message === null;
        if (promotionCodeApplied) {
            // The shopping cart was updated, so register the update so that the product added call is using latest data.
            window.disw.cartAnalytics.registerCartUpdate(cartData);
            window.disw.cartAnalytics.couponApplied(promotionCode);
        }
        else {
            // If the coupon entered is denied then also the data is sent to cartanalytics just to keep track of it.
            window.disw.cartAnalytics.couponDenied(promotionCode, cartData.message);
        }
        if (cartData) {
            emit(events.CART, cartData, !promotionCodeApplied);
        }
        return cartData;
    }
    catch (error) {
        logger.error(error);
        // Throw the error so it can be caught
        throw error;
    }
}
/**
 * Removes a promotional code from the DEX cart.
 *
 * @param {string} promotionCode the promotion code to remove from the cart
 *
 * @returns {Promise} Promise object representing the cart data as returned from the DEX middleware
 */
async function removePromotion(promotionCode) {
    const cartId = _getCartId();
    if (!cartId) {
        logger.warn(NO_CART_ID_ERROR);
        throw NO_CART_ID_ERROR;
    }
    try {
        // Remove the promotion code from the shopping cart.
        const cartData = await removePromotionCodeFromCart(cartEnv, cartId, promotionCode);
        // Register the cart update with the analytics module.
        window.disw.cartAnalytics.registerCartUpdate(cartData);
        // Fire the tracking event to track that the promotion code was removed from the shopping cart.
        window.disw.cartAnalytics.couponRemoved(promotionCode);
        // Let anyone else listening that the cart contents have changed
        if (cartData) {
            emit(events.CART, cartData, false);
        }
        return cartData;
    }
    catch (error) {
        logger.error(error);
        // Throw the error so it can be caught
        throw error;
    }
}
/**
 * Gets Product Information from DEX.
 *
 * Will throw an error if the current country is not supported by DEX for eCommerce, or if there is an
 * error getting the product info from DEX.
 *
 * @param {string} productSku the SKU for the product
 *
 * @returns {Promise} Promise object representing the product data as returned from the DEX middleware
 */
async function getProductInfo(productSku, compareTable = false) {
    var _a;
    // Get the current country code
    const countryCode = (_a = window.disw.country.getCountry()) === null || _a === void 0 ? void 0 : _a.countrycode;
    // Continue to look up the product from DEX
    try {
        const productData = await getProduct(cartEnv, productSku, countryCode);
        if (productData && !compareTable) {
            window.disw.cartAnalytics.productViewed(productData);
        }
        // Emit an event to indicate that a product was fetched from the Digital Exchange successfully.
        emit(events.ECOMMERCE, ECOMMERCE_EVENTS.ECOMMERCE_PRODUCT_FETCHED, productData);
        return productData;
    }
    catch (error) {
        logger.error(error);
        // Throw the error so it can be caught
        throw error;
    }
}
/**
 * Processes a list of composite products and extracts unique pricing options categorized by subscription plans.
 *
 * @param {Array<Product>} compositeProducts - An array of composite product objects, each containing pricing options.
 *
 * @returns {PricingOptionsDictionary}
 * - A dictionary categorizing unique pricing options by subscription plans.
 *
 * ### Example Output
 * ```json
 * {
 *   "THREE_YEAR_SUBSCRIPTION_DISPLAY_TERM": [...],
 *   "ANNUAL_SUBSCRIPTION_DISPLAY_TERM": [...],
 *   "THREE_MOTHS_TRIAL_DISPLAY_TERM": [...],
 *   "MONTHLY_SUBSCRIPTION_DISPLAY_TERM": [...]
 * }
 * ```
 *
 * ### Notes:
 * - Each pricing option is enriched with the product's name.
 * - Only pricing options with unique plans and auto-renew enabled are considered.
 * - A pricing option is included under a subscription plan only if all products have that plan.
 *
 * ### Error Handling:
 * - Logs an error and returns an empty dictionary if `compositeProducts` is not a valid array.
 */
function getPricingOptionsFromBundleProduct(compositeProducts) {
    // Initialize a dictionary to store pricing options categorized by subscription plans.
    const pricingOptionsFromBundleProduct = {
        [THREE_YEAR_SUBSCRIPTION_DISPLAY_TERM]: [],
        [ANNUAL_SUBSCRIPTION_DISPLAY_TERM]: [],
        [THREE_MOTHS_TRIAL_DISPLAY_TERM]: [],
        [MONTHLY_SUBSCRIPTION_DISPLAY_TERM]: []
    };
    // Validate the input. If the input is not an array, log an error and return an empty dictionary.
    if (!compositeProducts || !Array.isArray(compositeProducts)) {
        console.error(`Received the following for argument getPricingOptionsFromBundleProduct: ${compositeProducts}\nBut the expected argument type is Array<Product>`);
        return pricingOptionsFromBundleProduct;
    }
    // Check if all products have a specific subscription plan. This ensures uniformity across all products.
    const allProductsHasThreeYearSubscription = compositeProducts.every(product => { var _a; return (_a = product === null || product === void 0 ? void 0 : product.pricingOptions) === null || _a === void 0 ? void 0 : _a.some(option => (option === null || option === void 0 ? void 0 : option.plan) === THREE_YEAR_SUBSCRIPTION_DISPLAY_TERM); });
    const allProductsHasAnnualSubscription = compositeProducts.every(product => { var _a; return (_a = product === null || product === void 0 ? void 0 : product.pricingOptions) === null || _a === void 0 ? void 0 : _a.some(option => (option === null || option === void 0 ? void 0 : option.plan) === ANNUAL_SUBSCRIPTION_DISPLAY_TERM); });
    const allProductsHasThreeMonthsTrial = compositeProducts.every(product => { var _a; return (_a = product === null || product === void 0 ? void 0 : product.pricingOptions) === null || _a === void 0 ? void 0 : _a.some(option => (option === null || option === void 0 ? void 0 : option.plan) === THREE_MOTHS_TRIAL_DISPLAY_TERM); });
    const allProductsHasMonthlySubscription = compositeProducts.every(product => { var _a; return (_a = product === null || product === void 0 ? void 0 : product.pricingOptions) === null || _a === void 0 ? void 0 : _a.some(option => (option === null || option === void 0 ? void 0 : option.plan) === MONTHLY_SUBSCRIPTION_DISPLAY_TERM); });
    // Iterate over each product in the compositeProducts array.
    compositeProducts === null || compositeProducts === void 0 ? void 0 : compositeProducts.forEach((element) => {
        var _a;
        // Use a Set to keep track of unique plans for the current product.
        // This ensures only one pricing option is processed for each plan within a single product,
        // even if the product has multiple pricing options with the same plan.
        const plans = new Set();
        // Iterate through the pricing options of the current product.
        (_a = element === null || element === void 0 ? void 0 : element.pricingOptions) === null || _a === void 0 ? void 0 : _a.forEach((pricingOption) => {
            const plan = pricingOption === null || pricingOption === void 0 ? void 0 : pricingOption.plan;
            const autoRenew = pricingOption === null || pricingOption === void 0 ? void 0 : pricingOption.autoRenew;
            // Process only plans that are unique and have auto-renew enabled.
            if (plan && autoRenew && !plans.has(plan)) {
                // Mark the plan as processed.
                plans.add(plan);
                // Enrich the pricing option with the product name.
                const newPricingOption = { ...pricingOption, productName: element === null || element === void 0 ? void 0 : element.name };
                // Add the enriched pricing option to the corresponding subscription plan array if conditions are met.
                if (allProductsHasThreeYearSubscription && plan === THREE_YEAR_SUBSCRIPTION_DISPLAY_TERM) {
                    pricingOptionsFromBundleProduct[THREE_YEAR_SUBSCRIPTION_DISPLAY_TERM] = [...pricingOptionsFromBundleProduct[THREE_YEAR_SUBSCRIPTION_DISPLAY_TERM], newPricingOption];
                }
                if (allProductsHasAnnualSubscription && plan === ANNUAL_SUBSCRIPTION_DISPLAY_TERM) {
                    pricingOptionsFromBundleProduct[ANNUAL_SUBSCRIPTION_DISPLAY_TERM] = [...pricingOptionsFromBundleProduct[ANNUAL_SUBSCRIPTION_DISPLAY_TERM], newPricingOption];
                }
                if (allProductsHasThreeMonthsTrial && plan === THREE_MOTHS_TRIAL_DISPLAY_TERM) {
                    pricingOptionsFromBundleProduct[THREE_MOTHS_TRIAL_DISPLAY_TERM] = [...pricingOptionsFromBundleProduct[THREE_MOTHS_TRIAL_DISPLAY_TERM], newPricingOption];
                }
                if (allProductsHasMonthlySubscription && plan === MONTHLY_SUBSCRIPTION_DISPLAY_TERM) {
                    pricingOptionsFromBundleProduct[MONTHLY_SUBSCRIPTION_DISPLAY_TERM] = [...pricingOptionsFromBundleProduct[MONTHLY_SUBSCRIPTION_DISPLAY_TERM], newPricingOption];
                }
            }
        });
    });
    // Return the final categorized pricing options dictionary.
    return pricingOptionsFromBundleProduct;
}
/**
 * Update user locale by webkey.
 *
 * @returns {Promise} Promise object representing the USER data as returned from the DEX middleware
 */
async function updateSalesforceUserCountry() {
    // Get the "cart" country selected on the site.
    const country = window.disw.country.getCountry();
    // Get the account so we know which user to update.
    const account = window.disw.account.getAccount();
    try {
        await updateUserCountry(cartEnv, account.user.email, country.countrycode);
    }
    catch (error) {
        logger.error(error);
        // Throw the error so it can be caught
        throw error;
    }
}
/**
 * Change user locale and reprice cart for matching currency
 * Use keepInvalidItems=true to keep non-priced items in the cart without pricing options
 *
 * @returns {Promise} Promise object representing the cart data as returned from the DEX middleware
 */
async function updateCartCountry() {
    const keepInvalidItems = false;
    // Get the "cart" country (geolocation / country selector)
    const cartCountry = window.disw.country.getCountry();
    // Get the webkey account info. NOTE we cannot use webkey country. We require Salesforce country.
    const account = window.disw.account.getAccount();
    // Get their email, and then look-up their SF country via the DEX API.
    const cartId = _getCartId();
    if (!cartId) {
        logger.warn(NO_CART_ID_ERROR);
        throw NO_CART_ID_ERROR;
    }
    // First we need to get the users Salesforce country.
    let userCountry = { country: '' };
    try {
        // We need to look-up the users Salesforce country (note we CAN'T use their webkey email)
        userCountry = await getUserCountry(cartEnv, account.user.email);
    }
    catch (error) {
        // If we can't get the user country, we can't convert the cart so we should just quit.
        logger.error(error);
        throw error;
    }
    try {
        // Convert the cart to the country pulled from the users account
        const cartData = await convertCart(cartEnv, cartId, keepInvalidItems, userCountry.country);
        // Let the consumers know the cart has been updated.
        emit(events.CART, cartData === null || cartData === void 0 ? void 0 : cartData.cart, false);
        if (cartData) {
            // If the user has a US cart and CA user, the cart token should be updated: US_cartId should be renamed to CA_cartId
            let storageItemKey = `${cartCountry.countrycode}_${CART_STORAGE_KEY_SUFFIX}`;
            _deleteCartCookie(storageItemKey);
            // Update the cart ID in local storage
            storageItemKey = `${userCountry.country}_${CART_STORAGE_KEY_SUFFIX}`;
            _setCartCookie(storageItemKey, cartId);
            // Get the locale so we can fetch the list of countries to determine what it should be set to
            const currentLocale = window.disw.locales.getCurrentLocaleCode();
            // Get the list of countries to find the preselected country code
            window.disw.country.getAllCountries(currentLocale).then((countryList) => {
                // Find the country pulled from the users Salesforce account so we can set the country to same country the cart is now set to.
                const countryName = countryList.countries.find(country => country.value === userCountry.country);
                // Update the country to match the users account country (fallback to the userCountry if we can't find the country for some reason)
                window.disw.country.setCountry({
                    countryname: countryName ? countryName.label : userCountry.country,
                    countrycode: countryName ? countryName.value : userCountry.country
                });
            });
        }
        return cartData;
    }
    catch (error) {
        // Get the "cart" country (geolocation / country selector)
        const cartCountry = window.disw.country.getCountry();
        // If the cart conversion fails, we should delete the current cart ID because we don't know if the cart convert failed, if it timed out.
        // There's no clean way to handle this at the moment, so if the cart conversion fails, we'll delete the cart ID.
        const storageItemKey = `${cartCountry.countrycode}_${CART_STORAGE_KEY_SUFFIX}`;
        _deleteCartCookie(storageItemKey);
        logger.error(error);
        throw error;
    }
}
/**
 * Update the cart data by firing the `events.CART` event with the new cart data.
 *
 * @param newCartData The new data for the cart
 */
function updateCartData(newCartData) {
    emit(events.CART, newCartData, false);
}
/**
 * Clear the cart data after checkout is complete. This emits the `events.CART` event with
 * an error code for CART_CLEARED_AFTER_CHECKOUT so that we can avoid reloading the cartData
 * while the user is still on the Checkout screen, but the mini cart can be cleared.
 */
function clearCartDataAfterCheckout() {
    // If the user has a US cart and CA user, the cart token should be updated: US_cartId should be renamed to CA_cartId
    const storageItemKey = `${window.disw.country.getCountry().countrycode}_${CART_STORAGE_KEY_SUFFIX}`;
    // Delete cart cookies
    _deleteCartCookie(storageItemKey);
    emit(events.CART, null, CART_EVENT_ERRORS.CART_CLEARED_AFTER_CHECKOUT);
}
/** This is a function to return the URL for the Checkout Service Flow URL
 *
 * @return {string} string representing the base URL for the DEX Checkout Service
 *
 *  @example
 *     getCartCheckoutServiceUrl()
 */
function getCartCheckoutServiceUrl() {
    return dexCheckoutServiceURL;
}
/**
 * Return the URL for the webkey profile update page.
 *
 * @return {string} representing the URL for the webkey update profile page.
 */
function getWebkeyUpdateProfileUrl() {
    return webkeyUpdateProfileURL;
}
/**
 * Get the appropriate pricing option from the list based on the autoRenew and term values.
 * Note: This was added here so it can be accessed by universal components as well as siemens-shared-library
 *
 * @param {Array} pricingOptions - Array of options from a cart product response or cart
 * @returns matching pricing option or undefined
 *
 * @deprecated in favour of {@link getPricingOptions} as of 5/26.
 */
function getPricingOption(pricingOptions) {
    if (Array.isArray(pricingOptions)) {
        const option = pricingOptions.find(pricingOption => {
            var _a, _b, _c, _d, _e, _f;
            return pricingOption.autoRenew === true &&
                (((_a = pricingOption === null || pricingOption === void 0 ? void 0 : pricingOption.term) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === MONTHLY_SUBSCRIPTION_TERM || ((_b = pricingOption === null || pricingOption === void 0 ? void 0 : pricingOption.term) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === ANNUAL_SUBSCRIPTION_TERM) &&
                (((_c = pricingOption === null || pricingOption === void 0 ? void 0 : pricingOption.displayTerm) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === MONTHLY_SUBSCRIPTION_DISPLAY_TERM ||
                    ((_d = pricingOption === null || pricingOption === void 0 ? void 0 : pricingOption.displayTerm) === null || _d === void 0 ? void 0 : _d.toLowerCase()) === ANNUAL_SUBSCRIPTION_DISPLAY_TERM ||
                    ((_e = pricingOption === null || pricingOption === void 0 ? void 0 : pricingOption.displayTerm) === null || _e === void 0 ? void 0 : _e.toLowerCase()) === ANNUAL_SUBSCRIPTION_DISPLAY_TERM ||
                    ((_f = pricingOption === null || pricingOption === void 0 ? void 0 : pricingOption.displayTerm) === null || _f === void 0 ? void 0 : _f.toLowerCase()) === THREE_YEAR_SUBSCRIPTION_DISPLAY_TERM);
        });
        return option;
    }
    return undefined;
}
/**
 * Get the appropriate pricing options from the list based on the autoRenew and term values.
 * Note: This was added here so it can be accessed by universal components as well as siemens-shared-library
 *
 * @param {Array} pricingOptions - Array of options from a cart product response or cart
 * @param {string} [term] - Pricing Option term requested,
 * @returns {Array|Object} matching pricing options if more than 1, single pricing option if only 1, or undefined otherwise
 */
function getPricingOptions(pricingOptions, term) {
    if (Array.isArray(pricingOptions)) {
        const options = pricingOptions.filter(pricingOption => {
            var _a, _b;
            // Throw away the options that are not auto-renew
            if (!pricingOption.autoRenew) {
                return false;
            }
            if (!pricingOption.displayTerm || !DEFAULT_DISPLAY_TERMS_SUPPORTED.includes((_a = pricingOption.displayTerm) === null || _a === void 0 ? void 0 : _a.toLowerCase())) {
                return false;
            }
            if (!pricingOption.term || !DEFAULT_SUBSCRIPTION_TERMS_SUPPORTED.includes((_b = pricingOption.term) === null || _b === void 0 ? void 0 : _b.toLowerCase())) {
                return false;
            }
            // If a term was requested, check it
            if (term) {
                return pricingOption.term.toLowerCase() === term.toLowerCase();
            }
            // Otherwise, this matches!
            return true;
        });
        // More than one, return the list
        if (options.length > 1) {
            return options;
        }
        // Only 1, just return that entry
        if (options.length === 1) {
            return options[0];
        }
    }
    // Otherwise, nothing to return - undefined
    return undefined;
}
function isCheckoutEnabled() {
    return checkoutEnabled;
}
/**
 * Get the configured cart environment. The cart environment is set during the initialization
 * of the cart (in the initializeCart function).
 */
function getCartEnv() {
    return cartEnv;
}
/**
 * Set the cart environment. This is used for testing purposes.
 *
 * @param env the environment
 */
function _setCartEnv(env) {
    cartEnv = env;
}
export default {
    addOrSubtractFromCart,
    addOrSubtractProductsFromCart,
    ANNUAL_SUBSCRIPTION_TERM,
    ANNUAL_SUBSCRIPTION_DISPLAY_TERM,
    SAME_PRODUCT_DIFFERENT_TERM,
    applyPromotion,
    clearCartDataAfterCheckout,
    getCart,
    getCartCheckoutServiceUrl,
    getPricingOption,
    getPricingOptions,
    getPricingOptionsFromBundleProduct,
    getProductInfo,
    getWebkeyUpdateProfileUrl,
    isCheckoutEnabled,
    MONTHLY_SUBSCRIPTION_TERM,
    MONTHLY_SUBSCRIPTION_DISPLAY_TERM,
    THREE_YEAR_SUBSCRIPTION_DISPLAY_TERM,
    removeFromCart,
    removeProductFromCart,
    removePromotion,
    subscribeToCart,
    subscribeToECommerce,
    subscribeToFullCart,
    subscribeToMiniCart,
    toggleFullCart,
    toggleMiniCart,
    updateCartCountry,
    updateCartData,
    updateSalesforceUserCountry,
    CART_ERROR_INITIALIZING_CART: CART_EVENT_ERRORS.CART_ERROR_INITIALIZING_CART,
    CART_ERROR_FETCHING_CART_DATA: CART_EVENT_ERRORS.CART_ERROR_FETCHING_CART_DATA,
    ECOMMERCE_PRODUCT_FETCHED: ECOMMERCE_EVENTS.ECOMMERCE_PRODUCT_FETCHED,
    CART_CLEARED_AFTER_CHECKOUT: CART_EVENT_ERRORS.CART_CLEARED_AFTER_CHECKOUT,
    getCartEnv,
    _setCartEnv
};
