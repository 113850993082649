/**
 * Utilities for getting and setting cookies.
 */
const COOKIE_SEPARATOR = '; ';
const COOKIE_KEY_VALUE_SEPARATOR = '=';
const COOKIE_VALUE_INDEX = 1;
export const COOKIE_SAMESITE_NONE = 'None';
export const COOKIE_SAMESITE_LAX = 'Lax';
export const COOKIE_SAMESITE_STRICT = 'Strict';
export const COOKIE_DEFAULT_PATH = '/';
export const COOKIE_MAX_AGE = 34560000; // 400 Days - https://httpwg.org/http-extensions/draft-ietf-httpbis-rfc6265bis.html#name-the-expires-attribute
const COOKIE_DOMAIN_LOCALHOST = 'localhost';
const COOKIE_DOMAIN_DEV_UAT = '.stage.sw.siemens.com';
const COOKIE_DOMAIN_PROD = '.siemens.com';
const COOKIE_PAST_DATE = 'Thu, 01 Jan 1970 00:00:00 GMT';
/**
 * Get the proper domain for a cookie based on environment.
 * Note this uses the .stage.sw.siemens.com and .sw.siemens.com domains and supports local.sw.siemens.com.
 *
 * @param {boolean} [isDevOrUat=false] Whether this is a dev or UAT environment (defaults to prod). If this is dev or UAT, set this to `true`.
 */
export const getCookieDomain = (isDevOrUat = false) => {
    // Override default domain used for the cart cookie, if necessary
    if (['localhost', '127.0.0.1'].includes(location.hostname)) {
        return COOKIE_DOMAIN_LOCALHOST;
    }
    else if (['local.sw.siemens.com', 'plm-local.sw.siemens.com'].includes(location.hostname)) {
        return location.hostname;
    }
    return isDevOrUat ? COOKIE_DOMAIN_DEV_UAT : COOKIE_DOMAIN_PROD;
};
/**
 * Get the value of a cookie with the given key
 *
 * @param {string} key Cookie name
 * @returns {string|undefined} Cookie value or undefined if it doesn't exist
 */
export const getCookieValue = (key) => {
    const foundCookie = document.cookie.split(COOKIE_SEPARATOR).find(cookie => cookie.trimStart().startsWith(`${key}${COOKIE_KEY_VALUE_SEPARATOR}`));
    if (foundCookie) {
        return decodeURIComponent(foundCookie.split(COOKIE_KEY_VALUE_SEPARATOR)[COOKIE_VALUE_INDEX]);
    }
    // Otherwise we return undefined
    return undefined;
};
/**
 * Checks if cookies are enabled via if fs_uid cookie key exists
 *
 * @returns {boolean} true if fs_uid cookie is enabled, and therefore all other cookies  false otherwise,
 */
export const areCookiesEnabled = () => {
    const foundCookie = document.cookie.split(COOKIE_SEPARATOR).find(cookie => cookie.trimStart().startsWith(`fs_uid${COOKIE_KEY_VALUE_SEPARATOR}`));
    if (foundCookie) {
        return true;
    }
    return false;
};
/**
 * Set a cookie on the browser.  Note value will be encoded using encodeURIComponent. Of interest,
 * since we are only working with cookies through javascript we aren't setting the HttpOnly flag.
 *
 * @param {String} key Cookie name
 * @param {String} value Cookie value
 * @param {setCookieOptions} options Key value pairs for cookie options:
 */
export const setCookie = (key, value, options) => {
    const { expires, maxAge, sameSite, domain, path } = options;
    let cookieString = `${key}=${encodeURIComponent(value)};`;
    // Set the expiry.  Note the maxage takes precendence over expires
    if (maxAge) {
        cookieString = `${cookieString} Max-Age=${maxAge};`;
    }
    else if (expires) {
        cookieString = `${cookieString} Expires=${expires.toUTCString()};`;
    }
    if (domain) {
        cookieString = `${cookieString} Domain=${domain};`;
    }
    if (path) {
        cookieString = `${cookieString} Path=${path};`;
    }
    if (sameSite) {
        cookieString = `${cookieString} SameSite=${sameSite};${sameSite === COOKIE_SAMESITE_NONE && ' Secure;'}`;
    }
    // Now add the cookie
    document.cookie = cookieString;
};
/**
 * Delete a cookie with the specified criteria
 *
 * @param {String} key Cookie name
 * @param {deleteCookieOptions} options Key value pairs for cookie options:
 */
export const deleteCookie = (key, options) => {
    const { domain, path } = options;
    let cookieString = `${key}=;`;
    if (domain) {
        cookieString = `${cookieString} Domain=${domain};`;
    }
    if (path) {
        cookieString = `${cookieString} Path=${path};`;
    }
    // Now delete the cookie
    document.cookie = `${cookieString} Expires=${COOKIE_PAST_DATE}`;
};
