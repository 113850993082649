export const SUPPORTED_COUNTRIES = [
    {
        locale: 'da_DK',
        browserLocale: 'da_DK',
        currency: 'DKK',
        countryCode: 'DK',
        vatRequired: true,
        vatPrefixDisplay: 'DK'
    },
    {
        locale: 'en_GB',
        browserLocale: 'en_GB',
        currency: 'GBP',
        countryCode: 'GB',
        vatRequired: true,
        vatPrefixDisplay: 'GB;XI'
    },
    {
        locale: 'en_NZ',
        browserLocale: 'en_NZ',
        currency: 'AUD',
        countryCode: 'NZ',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'pt_PT',
        browserLocale: 'pt_PT',
        currency: 'EUR',
        countryCode: 'PT',
        vatRequired: true,
        vatPrefixDisplay: 'PT'
    },
    {
        locale: 'bg_BG',
        browserLocale: 'bg_BG',
        currency: 'EUR',
        countryCode: 'BG',
        vatRequired: true,
        vatPrefixDisplay: 'BG'
    },
    {
        locale: 'en_CA',
        browserLocale: 'en_CA',
        currency: 'CAD',
        countryCode: 'CA',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'iw_IL',
        browserLocale: 'iw_IL',
        currency: 'ILS',
        countryCode: 'IL',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'en_AU',
        browserLocale: 'en_AU',
        currency: 'AUD',
        countryCode: 'AU',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'nl_NL',
        browserLocale: 'nl_NL',
        currency: 'EUR',
        countryCode: 'NL',
        vatRequired: true,
        vatPrefixDisplay: 'NL'
    },
    {
        locale: 'pt_BR',
        browserLocale: 'pt_BR',
        currency: 'BRL',
        countryCode: 'BR',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'sv_SE',
        browserLocale: 'sv_SE',
        currency: 'SEK',
        countryCode: 'SE',
        vatRequired: true,
        vatPrefixDisplay: 'SE'
    },
    {
        locale: 'ko_KR',
        browserLocale: 'ko_KR',
        currency: 'KRW',
        countryCode: 'KR',
        vatRequired: true,
        vatPrefixDisplay: 'None'
    },
    {
        locale: 'hr_HR',
        browserLocale: 'hr_HR',
        currency: 'EUR',
        countryCode: 'HR',
        vatRequired: true,
        vatPrefixDisplay: 'HR'
    },
    {
        locale: 'de_DE',
        browserLocale: 'de_DE',
        currency: 'EUR',
        countryCode: 'DE',
        vatRequired: true,
        vatPrefixDisplay: 'DE'
    },
    {
        locale: 'pl_PL',
        browserLocale: 'pl_PL',
        currency: 'PLN',
        countryCode: 'PL',
        vatRequired: true,
        vatPrefixDisplay: 'None'
    },
    {
        locale: 'ja_JP',
        browserLocale: 'ja_JP',
        currency: 'JPY',
        countryCode: 'JP',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'fr_CH',
        browserLocale: 'fr_CH',
        currency: 'CHF',
        countryCode: 'CH',
        vatRequired: true,
        vatPrefixDisplay: 'CHE-'
    },
    {
        locale: 'en_IE',
        browserLocale: 'en_IE',
        currency: 'EUR',
        countryCode: 'IE',
        vatRequired: true,
        vatPrefixDisplay: 'IE'
    },
    {
        locale: 'cs_CZ',
        browserLocale: 'cs_CZ',
        currency: 'CZK',
        countryCode: 'CZ',
        vatRequired: true,
        vatPrefixDisplay: 'CZ'
    },
    {
        locale: 'zh_TW',
        browserLocale: 'zh_TW',
        currency: 'TWD',
        countryCode: 'TW',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'nl_BE',
        browserLocale: 'nl_BE',
        currency: 'EUR',
        countryCode: 'BE',
        vatRequired: true,
        vatPrefixDisplay: 'BE'
    },
    {
        locale: 'ro_RO',
        browserLocale: 'ro_RO',
        currency: 'EUR',
        countryCode: 'RO',
        vatRequired: true,
        vatPrefixDisplay: 'RO'
    },
    {
        locale: 'en_IN',
        browserLocale: 'en_IN',
        currency: 'INR',
        countryCode: 'IN',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'fr_FR',
        browserLocale: 'fr_FR',
        currency: 'EUR',
        countryCode: 'FR',
        vatRequired: true,
        vatPrefixDisplay: 'FR'
    },
    {
        locale: 'es_MX',
        browserLocale: 'es_MX',
        currency: 'MXN',
        countryCode: 'MX',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'de_AT',
        browserLocale: 'de_AT',
        currency: 'EUR',
        countryCode: 'AT',
        vatRequired: true,
        vatPrefixDisplay: 'AT'
    },
    {
        locale: 'en_ZA',
        browserLocale: 'en_ZA',
        currency: 'EUR',
        countryCode: 'ZA',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'fi_FI',
        browserLocale: 'fi_FI',
        currency: 'EUR',
        countryCode: 'FI',
        vatRequired: true,
        vatPrefixDisplay: 'FI'
    },
    {
        locale: 'zh_CN',
        browserLocale: 'zh_CN',
        currency: 'CNY',
        countryCode: 'CN',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'hu_HU',
        browserLocale: 'hu_HU',
        currency: 'EUR',
        countryCode: 'HU',
        vatRequired: true,
        vatPrefixDisplay: 'HU'
    },
    {
        locale: 'zh_HK',
        browserLocale: 'zh_HK',
        currency: 'HKD',
        countryCode: 'HK',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'zh_SG',
        browserLocale: 'zh_SG',
        currency: 'SGD',
        countryCode: 'SG',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'es_AR',
        browserLocale: 'es_AR',
        currency: 'USD',
        countryCode: 'AR',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'en_US',
        browserLocale: 'en_US',
        currency: 'USD',
        countryCode: 'US',
        vatRequired: false,
        vatPrefixDisplay: null
    },
    {
        locale: 'es_ES',
        browserLocale: 'es_ES',
        currency: 'EUR',
        countryCode: 'ES',
        vatRequired: true,
        vatPrefixDisplay: 'ES'
    },
    {
        locale: 'it_IT',
        browserLocale: 'it_IT',
        currency: 'EUR',
        countryCode: 'IT',
        vatRequired: true,
        vatPrefixDisplay: 'IT'
    },
    {
        locale: 'no_NO',
        browserLocale: 'no_NO',
        currency: 'EUR',
        countryCode: 'NO',
        vatRequired: true,
        vatPrefixDisplay: 'NO'
    }
];
